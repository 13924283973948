export const sosHeader = [
	'SalesOrderNumber',
	'Customer',
	'OrderDate',
	'BillAddressLine1',
	'BillAddressLine2',
	'BillAddressLine3',
	'BillAddressLine4',
	'BillAddressLine5',
	'BillCity',
	'Item',
	'Category',
	'BillState',
	'BillZip',
	'BillCountry',
	'ShipAddressLine1',
	'ShipAddressLine2',
	'ShipAddressLine3',
	'ShipAddressLine4',
	'ShipAddressLine5',
	'ShipCity',
	'ShipState',
	'ShipZip',
	'ShipCountry',
	'BillEmail',
	'BillPhone',
	'ShipEmail',
	'ShipPhone',
	'BillCompany',
	'BillContact',
	'ShipCompany',
	'ShipContact',
	'CustomerMessage',
	'Memo',
	'IsClosed',
	'Shipping',
	'CustomerPO',
	'SalesRep',
	'Terms',
	'Department',
	'Class',
	'Channel',
	'Currency',
	'AssignedTo',
	'Priority',
	'OrderStage',
	'DepositPercent',
	'Deposit',
	'DropShip',
	'DiscountTaxable',
	'Discount',
	'ExchangeRate',
	'Description',
	'Quantity',
	'Rate',
	'Amount',
	'Tax',
	'Shipped',
	'Invoiced',
	'DueDate',
	'Picked',
	'Taxable',
	'LineNumber',
	'QuantityRaw',
	'Cost',
	'Margin',
	'ListPrice',
	'PercentDiscount',
	'Job',
	'TaxCode',
	'UOM',
	'Location',
	'Archived',
];

export const poHeader = [
	'SHIPPOINT',
	'LOADINGMETHOD',
	'PONUMBER',
	'CASES',
	'WEIGHT',
	'QUANTITY',
];
