import { append, create, on } from 'dtk';
import { ujsx, ujsxToDOM } from 'ujsx';
import * as csvParse from 'csv-parse/lib/sync';
import * as csvStringify from 'csv-stringify/lib/sync';

import './style.scss';
import { poHeader, sosHeader } from './headers';

const customer = 'Walmart';
const item = 'Retail Case Joe\'s Gourmet Fish Fry Orginal 12 oz';
const country = 'US';
const terms = 'Net 30';
const rate = 13.38;

const shipPoint = '74508300';
const loadingMethod = 'Floor';
const weightMul = 4.5;
const qtyMul = 0.3118;

const input = create('input', { type: 'file' });
const debug = create('div', 'sosedi-debug');
const output = create('div', 'sosedi__block');

append(document.body, ujsxToDOM(
	<div className="sosedi">
		<div className="sosedi__block">
			<strong>Select file:</strong><br />
			{input}
		</div>
		{output}
	</div>
) as HTMLElement, debug);

function colIdx(istr: string) {
	let num = 0;
	let str = istr.toUpperCase();
	while (str.length) {
		num = num * 26 + (str.charCodeAt(0) - 64);
		str = str.slice(1);
	}
	return num - 1;
}

function nulls(n: number) {
	return Array(n).fill(null);
}

on(input, 'change', async e => {

	const file = input.files![0];
	if (!file) return;

	const istr = await new Promise<string>((resolve, reject) => {
		const fr = new FileReader();
		fr.onerror = reject;
		fr.onload = () => resolve(fr.result as string);
		fr.readAsText(file);
	});

	const rows = csvParse(istr, {
		relax_column_count: true,
	}) as string[][];

	const header = rows.shift()!;
	const map: Record<string, number> = {};
	header.forEach((str, i) => map[str] = i);

	const sos: (string | number | null)[][] = [sosHeader];
	const po: (string | number | null)[][] = [poHeader];

	for (let row of rows) {

		const col = (id: string) => row[colIdx(id)] || '**EMPTY**';
		const hdr = (id: string) => row[map[id]];

		if (hdr('Record Type') != 'H') continue;

		const qty = Math.round(parseFloat(col('CS')) / rate);

		sos.push([
			col('A'),
			customer,
			col('C'),
			col('CC'),
			...nulls(4),
			col('CE'),
			item,
			null,
			col('CF'),
			col('CG'),
			country,
			col('CC'),
			col('A'),
			...nulls(3),
			col('CE'),
			col('CF'),
			col('CG'),
			country,
			...nulls(4),
			customer,
			...nulls(9),
			terms,
			...nulls(14),
			qty,
			rate,
			col('CS'),
			...nulls(7),
			qty,
		]);

		po.push([
			shipPoint,
			loadingMethod,
			col('A'),
			qty,
			Math.ceil(qty * weightMul),
			Math.ceil(qty * qtyMul),
		]);

	}

	const fnm = file.name.match(/ \d+/);
	const date = fnm && fnm[0] || '';
	const links = ([[sos, 'SOS'], [po, 'PO']] as [any, string][]).map(([data, label]) => {
		const csv = csvStringify(data);
		const fn = `${label}${date}.csv`;
		const blob = new Blob([csv], {
			type: 'text/csv',
			endings: 'native',
		});
		return create('a', { $: {
			href: URL.createObjectURL(blob),
			download: fn,
		} }, fn)
	});

	output.innerHTML = '';
	append(
		output,
		create('strong', {}, 'Download'),
		create('ul', {}, ...links.map(l => create('li', {}, l)))
	);

});

